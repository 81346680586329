import React, { useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useAsyncFn } from "react-use";

import { Header } from "./header";
import { Footer } from "./footer";
import { Cookies } from "../cookies/cookies";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useDraftProductsContext } from "../context/draft-products";

const Main = styled.main`
  min-height: calc(${props => props.height}px - 50px - 40px);
`;

export const App = ({
  location,
  children,
  pageSetup,
  setPageSetup,
  height,
}) => {
  const isDesktop = useMediaQuery("(min-width: 961px)");
  const { setSessionStorageDraftProducts } = useDraftProductsContext();
  const fetchTimeoutRef = useRef(null);
  const isMountedRef = useRef(true); // Add mounted ref to track component lifecycle

  const [fetchState, fetchDraftProducts] = useAsyncFn(async () => {
    const response = await fetch(`/.netlify/functions/get-all-draft-products`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch draft products");
    }

    return await response.json();
  }, []);

  const handleFetchDraftProducts = useCallback(async () => {
    try {
      const result = await fetchDraftProducts();

      // Only update state if component is still mounted
      if (isMountedRef.current && result?.products) {
        setSessionStorageDraftProducts(result.products);

        // Schedule next fetch
        clearTimeout(fetchTimeoutRef.current);
        fetchTimeoutRef.current = setTimeout(
          handleFetchDraftProducts,
          5 * 60 * 1000
        );
      }
    } catch (error) {
      console.error("Error fetching draft products:", error);

      // Only schedule retry if component is still mounted
      if (isMountedRef.current) {
        clearTimeout(fetchTimeoutRef.current);
        fetchTimeoutRef.current = setTimeout(
          handleFetchDraftProducts,
          60 * 1000
        );
      }
    }
  }, [fetchDraftProducts, setSessionStorageDraftProducts]);

  useEffect(() => {
    // Set mounted ref to true
    isMountedRef.current = true;

    // Initialize data fetching
    handleFetchDraftProducts();

    // Cleanup function
    return () => {
      isMountedRef.current = false; // Set mounted ref to false
      clearTimeout(fetchTimeoutRef.current); // Clear any pending timeouts
    };
  }, [handleFetchDraftProducts]);

  return (
    <>
      <Helmet
        meta={[
          {
            name: "viewport",
            content:
              "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
          },
          {
            name: "facebook-domain-verification",
            content: "qduqjt2j96c918931sthmcr3sqh0m0",
          },
          {
            name: "google-site-verification",
            content: "4VqnUHS8jF8V-dRtpCSHi-0uj2OqRSpotnKa32OMw2E",
          },
        ]}
      />
      {pageSetup.pageType !== "delivery" && (
        <Header
          location={location}
          pageSetup={pageSetup}
          setPageSetup={setPageSetup}
          isDesktop={isDesktop}
        />
      )}

      <Main height={height}>{children}</Main>

      {pageSetup.pageType !== "delivery" && isDesktop && (
        <Footer location={location} pageSetup={pageSetup} />
      )}

      <Cookies location={location} />
    </>
  );
};
