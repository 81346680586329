import React, { createContext, useContext, useState, useCallback } from "react";

const DraftProductsContext = createContext();

export const DraftProductsProvider = ({ children }) => {
  const [draftProducts, setDraftProducts] = useState([]);

  const setSessionStorageDraftProducts = useCallback(products => {
    setDraftProducts(products);
    sessionStorage.setItem("draftProducts", JSON.stringify(products));
  }, []); // Memoized setter

  return (
    <DraftProductsContext.Provider
      value={{
        draftProducts,
        setSessionStorageDraftProducts,
      }}
    >
      {children}
    </DraftProductsContext.Provider>
  );
};

export const useDraftProductsContext = () => {
  const context = useContext(DraftProductsContext);
  if (!context) {
    throw new Error(
      "useDraftProductsContext must be used within a DraftProductsProvider"
    );
  }
  return context;
};
